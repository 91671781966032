// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-404-js": () => import("./../../../src/pages/404/404.js" /* webpackChunkName: "component---src-pages-404-404-js" */),
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-acerca-de-nosotros-about-us-js": () => import("./../../../src/pages/acerca-de-nosotros/about-us.js" /* webpackChunkName: "component---src-pages-acerca-de-nosotros-about-us-js" */),
  "component---src-pages-acerca-de-nosotros-index-js": () => import("./../../../src/pages/acerca-de-nosotros/index.js" /* webpackChunkName: "component---src-pages-acerca-de-nosotros-index-js" */),
  "component---src-pages-calculadora-index-js": () => import("./../../../src/pages/calculadora/index.js" /* webpackChunkName: "component---src-pages-calculadora-index-js" */),
  "component---src-pages-calculadora-loan-calculator-js": () => import("./../../../src/pages/calculadora/loan-calculator.js" /* webpackChunkName: "component---src-pages-calculadora-loan-calculator-js" */),
  "component---src-pages-faqs-faqs-js": () => import("./../../../src/pages/faqs/faqs.js" /* webpackChunkName: "component---src-pages-faqs-faqs-js" */),
  "component---src-pages-faqs-index-js": () => import("./../../../src/pages/faqs/index.js" /* webpackChunkName: "component---src-pages-faqs-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-de-privacidad-index-js": () => import("./../../../src/pages/politica-de-privacidad/index.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-index-js" */),
  "component---src-pages-politica-de-privacidad-privacy-policy-js": () => import("./../../../src/pages/politica-de-privacidad/privacy-policy.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-privacy-policy-js" */),
  "component---src-pages-terminos-y-condiciones-index-js": () => import("./../../../src/pages/terminos-y-condiciones/index.js" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-index-js" */),
  "component---src-pages-terminos-y-condiciones-terms-conditions-js": () => import("./../../../src/pages/terminos-y-condiciones/terms-conditions.js" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-terms-conditions-js" */)
}

